import { Route, Routes } from "react-router-dom";
import UserList from "../pages/UserList.js";
import UserDashboard from "../pages/UserDashboard.js";
import SubscriptionList from "../pages/SubscriptionList";
import AddSubscription from "../pages/AddSubscription";
import UserDetail from "../pages/UserDetail";
import SubscriptionDetail from "../pages/SubscriptionDetail";
import EditSubscription from "../pages/EditSubscription";
import Login from "../pages/Login.js";
import { useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext.js";
import { ScrollToTop } from "./ScrollToTop.js";
import TransactionList from "../pages/TransactionList.js";
import { ChangePassword } from "../pages/ChangePassword.js";

function Routing() {
  const globalContext = useContext(GlobalContext);

  return (
    <ScrollToTop>
      <Routes>
        {!globalContext.loggedIn ? (
          <>
            <Route path="*" element={<Login />} />
          </>
        ) : (
          <>
            <Route path="*" element={<UserDashboard />} />
            <Route path="/users" element={<UserList />} />
            <Route path="/subscriptions" element={<SubscriptionList />} />
            <Route path="/addSubscription" element={<AddSubscription />} />
            <Route path="/editSubscription" element={<EditSubscription />} />
            <Route path="/userdetail" element={<UserDetail />} />
            <Route
              path="/subscriptiondetail"
              element={<SubscriptionDetail />}
            />
            <Route path="/transactions" element={<TransactionList />} />
            <Route path="/changepassword" element={<ChangePassword />} />
          </>
        )}
      </Routes>
    </ScrollToTop>
  );
}
export default Routing;
