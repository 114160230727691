import { Modal } from "react-bootstrap";

export const CancelReasonModal = ({ reason, showReason, hide }) => {
  return (
    <Modal className="view-cancel"
      show={showReason}
      onHide={hide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Cancelation Reason</Modal.Title>
      </Modal.Header>
      <Modal.Body>{reason}</Modal.Body>
    </Modal>
  );
};
