import React, { useContext, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { GlobalContext } from "../../context/GlobalContext";

export const ScrollToTop = (props) => {

    const location = useLocation();
    const globalContext = useContext(GlobalContext);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location])

    return (<div className={`col-lg-${globalContext.loggedIn ? "10" : "12"} full-width-sidebar`}>{props.children}</div>)
}