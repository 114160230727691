import LogoLogin from "../assets/images/Botfather-login.png";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { LoginAdmin } from "../../service/UserServices";
import Swal from "sweetalert2";
import { useContext, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";

function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const globalContext = useContext(GlobalContext);
  let navigate = useNavigate();
  const [pass, setPass] = useState("password");

  const signIn = (data) => {
    globalContext.setLoader(true)
    LoginAdmin(data)
      .then((res) => {
          globalContext.setLoggedIn(true);
          globalContext.setUserInfo(res.data.data);
          sessionStorage.setItem("token", res.data.token);
          sessionStorage.setItem("user-info", JSON.stringify(res.data.data));
          globalContext.setLoader(false)
        navigate("/dashboard");
      })
      .catch((err) => {
        globalContext.setLoader(false)
        console.log("error: ", err);
        if (err.response.status === 400)
          Swal.fire(err.response.data.message, "", "error");
      });
  };

  return (
    <div className="bg-login">
      <div className="login col-lg-4 m-auto">
        <img src={LogoLogin} alt="logo-login" className="zoom-in-zoom-out" />
        <p className="logo-sub-title">
          Hi Welcome to <span>BOTFATHER!</span>
        </p>
        <Form>
          <div className="row">
            <div className="login-data col-lg-12">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Email"
                  {...register("email", { required: "Email is required" })}
                />
              </Form.Group>
              {errors.email && (
                <span className="error-msg">{errors.email.message}</span>
              )}
            </div>

            <div className="col-lg-12 login-data">
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasictext"
              >
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type={pass}
                  placeholder="Enter Password"
                  {...register("password", {
                    required: "Password is required",
                  })}
                />
                <i
                  className={`fa fa-eye${
                    pass === "password" ? "" : "-slash"
                  } eye-password`}
                  aria-hidden="true"
                  onClick={() =>
                    setPass((pass) =>
                      pass === "password" ? "text" : "password"
                    )
                  }
                ></i>
              </Form.Group>
              {errors.password && (
                <span className="error-msg">{errors.password.message}</span>
              )}
              <button className="btn btn-sign" onClick={handleSubmit(signIn)}>
                Sign in
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}
export default Login;
