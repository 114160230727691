import React from "react";
import { useState } from "react";
import { GlobalContext } from "./GlobalContext";

export const GlobalState = (props) => {

    const [loader, setLoader] = useState(false);
    const [loggedIn, setLoggedIn] = useState(sessionStorage.getItem('token') ? true : false);
    const [userInfo, setUserInfo] = useState(sessionStorage.getItem('user-info') ? JSON.parse(sessionStorage.getItem('user-info')) : {});
    const [sessionComplete, setSessionComplete] = useState(false);

    const value = {
        loader, setLoader,
        loggedIn, setLoggedIn,
        userInfo, setUserInfo,
        sessionComplete, setSessionComplete,
    }

    return (
        <GlobalContext.Provider value={value}>
            {props.children}
        </GlobalContext.Provider>
    )

}