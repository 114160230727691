function Footer() {
  return (
    <div className='row footer-dashboard'>
      <div className='sidenav py-2 col-2 admin-footer-side'>
      </div>
      <div className='col-lg-10 full-width-sidebar  col-12 px-3 py-3 bg-black h-85'>
        <p className="copywrite">
          Copyright {new Date().getFullYear()} All Rights Reserved.
        </p>
      </div>
    </div>
  )
}
export default Footer;