import { useContext } from "react";
import "./App.css";
import Footer from "./components/commons/Footer";
import Header from "./components/commons/Header";
import { Loader } from "./components/commons/Loader";
import Routing from "./components/commons/Routing";
import SideBar from "./components/commons/Sidebar";
import { GlobalContext } from "./context/GlobalContext";

function App() {
  const globalContext = useContext(GlobalContext);

  return (
    <div className="App">
      {globalContext.loader && <Loader />}
      {globalContext.loggedIn && <Header />}
      <div className="sidenav py-2">
        <div className="container-fluid">
          <div className="row">
            {globalContext.loggedIn && <SideBar />}
            <Routing />
          </div>
          {globalContext.loggedIn && <Footer />}
        </div>
      </div>
    </div>
  );
}

export default App;
