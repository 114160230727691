import ticknew from "../assets/images/tick-new.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import {
  PlanDetailApi,
  subscriptionTransactions,
} from "../../service/UserServices";
import { GlobalContext } from "../../context/GlobalContext";
import { DataPagination } from "../commons/DataPagination";
import { DateFormat } from "../commons/DateFormat";
import { CancelReasonModal } from "../commons/CancelReasonModal";

function SubscriptionDetail() {
  const usertitle = "Subscription Detail";
  const globalContext = useContext(GlobalContext);
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const [data, setData] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [showReason, setShowReason] = useState(false);
  const [reason, setReason] = useState("");

  useEffect(() => {
    const plan = query.get("id");
    getPlanDetails(plan);
    getSusbscriptionTransactions(plan);
  }, [query, page]);

  const getPlanDetails = (plan) => {
    globalContext.setLoader(true);
    PlanDetailApi(plan)
      .then((res) => {
        setData(res.data.data);
        globalContext.setLoader(false);
      })
      .catch((err) => {
        globalContext.setLoader(false);
        console.log("error: ", err.response);
      });
  };

  const getSusbscriptionTransactions = (id) => {
    subscriptionTransactions(id, page - 1)
      .then((res) => {
        setTransactions(res.data.data);
        setTotalCount(res.data.totalCount);
      })
      .catch((err) => {
        console.log("error: ", err.response);
      });
  };

  function closeViewReasonModal() {
    setReason("");
    setShowReason(false);
  }

  return (
    <>
      <div className="row subscription-plan-detail">
        <div className="col-lg-12 col-12 px-4 py-4 bg-black user-table">
          <div className="table-main subscription">
            <div className="main-table-box table-responsive userlist subscript mt-5">
              <div className="d-flex align-items-center subscribe-main">
                <div className="heading-top text-left">
                  <h2>{usertitle}</h2>
                  <h5>
                    <Link to="/dashboard">
                      <i className="fa fa-home"></i>Home
                    </Link>{" "}
                    /{" "}
                    <Link onClick={() => navigate(-1)}>
                      {" "}
                      Subscription Plans
                    </Link>
                    / <>Subscription Detail</>
                  </h5>
                </div>
                {data?.hasTrial === "1" && (
                  <h4 className="free-trial-avail">
                    Free trial available for 48 hours
                  </h4>
                )}
              </div>
              <div className="enterprice sub-detail">
                <h5>{data.planName}</h5>
                <h2>
                  ${data.planPrice}/<span>{data.durationUnit}</span>
                </h2>

                <h4>Benefits:</h4>
                <ul>
                  {data?.benifits?.map((b) => (
                    <li>
                      <img src={ticknew} alt="tick" />
                      {b}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {transactions.length > 0 && (
            <div className="sidenav py-2 mt-5">
              <div className="container-fluid">
                <div className="row">
                  <div className="table-main subscription">
                    <div className="main-table-box table-responsive userlist">
                      <div className="d-flex align-items-center subscribe-main mb-4">
                        <div className="heading-top text-left">
                          <h2>Subscription Purchase Transactions</h2>
                        </div>
                      </div>
                      <div className="row">
                        <table className="table py-5 mt-3">
                          <thead>
                            <tr>
                              <th scope="col">Paypal Subscription ID</th>
                              <th scope="col">User Name</th>
                              <th scope="col">Plan Price</th>
                              <th scope="col">Price Paid</th>
                              <th scope="col">Purchased On</th>
                              <th scope="col">Expiry Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transactions.map((t, j) => (
                              <tr key={j}>
                                <td>{t.paypalSubscriptionId}</td>
                                <td>
                                  {t._user.firstName + " " + t._user.lastName}
                                </td>
                                <td>
                                  ${t.planPrice}/{data.durationUnit}
                                </td>
                                <td>${t?.amountPaid ? t?.amountPaid : "0"}</td>
                                <td>{DateFormat(t.startTime)}</td>
                                <td>
                                  {t.status === "CANCELLED" ? (
                                    <span>
                                      {`Cancelled by ${
                                        t.cancelledBy === "U" ? "User" : "Admin"
                                      }`}{" "}
                                      <span
                                        className="view-reason"
                                        onClick={() => {
                                          setReason(t.cancelReason);
                                          setShowReason(true);
                                        }}
                                      >
                                        View Reason
                                      </span>
                                    </span>
                                  ) : new Date(t.expireAt) < new Date() ? (
                                    "Expired"
                                  ) : (
                                    DateFormat(t.expireAt)
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        {totalCount > 10 && (
                          <DataPagination
                            page={page}
                            totalItems={totalCount}
                            changePage={(e) => setPage(e)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <CancelReasonModal
        reason={reason}
        showReason={showReason}
        hide={closeViewReasonModal}
      />
    </>
  );
}
export default SubscriptionDetail;
