import Navigation from "./Navigation"
import { Navbar } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
function SideBar() {
       return (
              <div className='sidenav admin-sidebar py-2 col-2'>
                     <Navbar.Toggle aria-controls="basic-navbar-nav" />
                     <Navigation />
              </div>
       )
}
export default SideBar