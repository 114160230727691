import eye from "../assets/images/eye.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { UserListApi } from "../../service/UserServices";
import { DataPagination } from "../commons/DataPagination";
import { GlobalContext } from "../../context/GlobalContext";
import { ReactTooltip } from "../commons/ReactTooltip";
import { DateFormat } from "../commons/DateFormat";
import { Form } from "react-bootstrap";

function UserList() {
  const headingtitle = "User Management";

  let navigate = useNavigate();
  const [query] = useSearchParams();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const globalContext = useContext(GlobalContext);
  const [search, setSearch] = useState("");
  const [subs, setSubs] = useState("");

  useEffect(() => {
    globalContext.setLoader(true);
  }, []);

  useEffect(() => {
    let currentPage = query.get("page");
    let searchData = query.get("search");
    let filterData = query.get("type");
    setPage(parseInt(currentPage));
    setSearch(searchData);
    setSubs(filterData);
    getUser(parseInt(currentPage) - 1, searchData, filterData);
  }, [query]);

  function handleClick(id) {
    navigate(`/userdetail?id=${id}`);
  }

  const changePage = (newPage) => {
    globalContext.setLoader(true);
    navigate({ search: `?page=${newPage}&search=${search}&type=${subs}` });
  };

  const getUser = (currentPage, search, subs) => {
    UserListApi(currentPage, search, subs)
      .then((res) => {
        setData(res.data.data);
        setTotalCount(res.data.totalCount);
        globalContext.setLoader(false);
      })
      .catch((err) => {
        globalContext.setLoader(false);
        console.log("error: ", err.response);
      });
  };

  return (
    <div className="row">
      <div className="col-lg-12 col-12 px-4 py-4 bg-black user-table">
        <div className="sidenav py-2">
          <div className="container-fluid">
            <div className="row">
              <div className="main-table-box table-responsive userlist">
                <div className="table-main">
                  <div className="table-heading">
                    <div className="heading-top text-left">
                      <h2>{headingtitle}</h2>

                      <h5>
                        <Link to="/dashboard">
                          <i className="fa fa-home"></i>Home
                        </Link>{" "}
                        / <>Users List</>
                      </h5>
                    </div>

                    <div className="search-right-main">
                      <div className="search-filter-all">
                        <input
                          className="search-user"
                          placeholder="Search Here.."
                          value={search}
                          onChange={(e) => {
                            navigate({
                              search: `?page=${1}&search=${e.target.value.trimStart()}&type=${subs}`,
                            });
                          }}
                          type="text"
                        />
                        <i
                          className="search-user-data fa fa-search"
                          aria-hidden="true"
                        ></i>
                      </div>

                      <div className="btn btn-filter float-end">
                        <Form.Group
                          className="btn btn-link nav-link d-flex align-items-center"
                          id="bd-theme"
                          controlId="formBasictext"
                        >
                          <Form.Select
                            aria-label="Default select example"
                            value={subs}
                            onChange={(e) =>
                              navigate({
                                search: `?page=${1}&search=${search}&type=${
                                  e.target.value
                                }`,
                              })
                            }
                          >
                            <option value="">All</option>
                            <option value="true">Subscribed</option>
                            <option value="false">Not Subscribed</option>
                          </Form.Select>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                  <table className="table py-5 mt-3">
                    <thead>
                      <tr>
                        <th scope="col">User Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Subscription Plan</th>
                        <th scope="col">Expiry Date</th>
                        <th scope="col">Price</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length > 0 &&
                        data.map((d, i) => (
                          <tr key={i}>
                            <th scope="row">
                              {d.firstName + " " + d.lastName}
                            </th>
                            <td>{d.email}</td>
                            <td>{d.activePlan ? d.activePlan : "N/A"}</td>
                            <td>
                              {d.planExpiry
                                ? new Date(d.planExpiry) < new Date()
                                  ? "Expired"
                                  : DateFormat(d.planExpiry)
                                : "N/A"}
                            </td>
                            <td>{d.planPrice ? `$${d.planPrice}` : "N/A"}</td>

                            <td>
                              <ReactTooltip text="View Details">
                                <img
                                  src={eye}
                                  alt="eye"
                                  onClick={() => handleClick(d._id)}
                                />
                              </ReactTooltip>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>

                  {data.length <= 0 && (
                    <div className="text-white no-data">No Data Found</div>
                  )}
                </div>
                {totalCount > 10 && (
                  <DataPagination
                    page={page}
                    totalItems={totalCount}
                    changePage={changePage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default UserList;
