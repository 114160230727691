import { useContext } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { GlobalContext } from "../../context/GlobalContext";
import { ChangePasswordApi } from "../../service/UserServices";
import LogoLogin from "../assets/images/Botfather-login.png";

export const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm();
  const globalContext = useContext(GlobalContext);
  const navigate = useNavigate();

  const changePass = (data) => {
    if (data.newPassword !== data.confirmPassword) {
      setError("confirmPassword", {
        message: "Password and confirm password should be same",
      });
    } else {
      globalContext.setLoader(true);
      ChangePasswordApi(data)
        .then((res) => {
          globalContext.setLoader(false);
          Swal.fire(
            "Success",
            "Password changed successfully.",
            "success"
          ).then(() => navigate("/dashboard"));
          reset();
        })
        .catch((err) => {
          globalContext.setLoader(false);
          Swal.fire("Error", err.response.data.message, "error");
          console.log("Error: ", err);
        });
    }
  };

  return (
    <div className="bg-login">
      <div className="login col-lg-4 m-auto changepass">
       
        <Form onSubmit={handleSubmit(changePass)}>
          <div className="row">
            <div className="login-data col-lg-12">
              <Form.Group className="mb-3" controlId="formBasicpass">
                <Form.Control
                  type="password"
                  placeholder="Old Password"
                  {...register("oldPassword", {
                    onChange: (e) => {
                      if (e.target.value === " ") {
                        setValue("oldPassword", "");
                      }
                    },
                    required: "Old password is required",
                  })}
                />
                {errors.oldPassword && (
                  <span className="error-msg">
                    {errors.oldPassword.message}
                  </span>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicpass">
                <Form.Control
                  type="password"
                  placeholder="New Password"
                  {...register("newPassword", {
                    onChange: (e) => {
                      if (e.target.value === " ") {
                        setValue("newPassword", "");
                      }
                    },
                    required: "Password is required",
                    pattern: {
                      value:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s])(?!.*\s).{8,}$/,
                      message:
                        "Password must contain 1 lower case, 1 upper case, 1 number and 1 special character.",
                    },
                    minLength: {
                      value: 8,
                      message: " Password must be at least 8 characters long",
                    },
                  })}
                />
                {errors.newPassword && (
                  <span className="error-msg">
                    {errors.newPassword.message}
                  </span>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicpass">
                <Form.Control
                  type="password"
                  placeholder="Confirm Password"
                  {...register("confirmPassword", {
                    onChange: (e) => {
                      if (e.target.value === " ") {
                        setValue("confirmPassword", "");
                      }
                    },
                    required: "Confirm password is required",
                  })}
                />
                {errors.confirmPassword && (
                  <span className="error-msg">
                    {errors.confirmPassword.message}
                  </span>
                )}
              </Form.Group>
            </div>

            <div className="col-lg-12 login-data">
              <button className="btn btn-sign">Submit</button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};
