import axios from "axios";
import { BASE_URL } from "../configuration/Config";
import { Headers } from "../configuration/ConfigHeaders";

function configHeaders() {
  return {
    headers: Headers(),
  };
}

export const LoginAdmin = (data) => {
  let url = `${BASE_URL}/admin/login`;
  return axios.post(url, data);
};

export const DashboardApi = () => {
  let url = `${BASE_URL}/admin/dashboard`;
  return axios.get(url, configHeaders());
};

export const UserListApi = (page, search, subs) => {
  let url = `${BASE_URL}/admin/users?page=${page}&search=${search}&subscription=${subs}`;
  return axios.get(url, configHeaders());
};

export const UserDetailApi = (id) => {
  let url = `${BASE_URL}/admin/users/${id}`;
  return axios.get(url, configHeaders());
};

export const SubscriptionListApi = (page) => {
  let url = `${BASE_URL}/admin/subscriptions?page=${page}`;
  return axios.get(url, configHeaders());
};

export const UpdatePlanStatusApi = (id, params) => {
  let url = `${BASE_URL}/admin/subscriptions/${id}`;
  return axios.patch(url, params, configHeaders());
};

export const PlanDetailApi = (id) => {
  let url = `${BASE_URL}/admin/subscriptions/${id}`;
  return axios.get(url, configHeaders());
};

export const AddPlanApi = (data) => {
  let url = `${BASE_URL}/admin/subscriptions/add`;
  return axios.post(url, data, configHeaders());
};

export const EditPlanApi = (id, params) => {
  let url = `${BASE_URL}/admin/subscriptions/${id}`;
  return axios.put(url, params, configHeaders());
};

export const UserTransactions = (id, page) => {
  let url = `${BASE_URL}/admin/users/${id}/transactionList?page=${page}`;
  return axios.get(url, configHeaders());
};

export const TotalTransactions = (page, search, dateStart, dateEnd) => {
  let url = `${BASE_URL}/admin/transactions?page=${page}&search=${search}&dateStart=${dateStart}&dateEnd=${dateEnd}&sortOrder=DESC`;
  return axios.get(url, configHeaders());
};

export const CancelUserSubscription = (id, params) => {
  let url = `${BASE_URL}/admin/users/${id}/cancelSubscription`;
  return axios.post(url, params, configHeaders());
};

export const subscriptionTransactions = (id, page) => {
  let url = `${BASE_URL}/admin/subscriptions/${id}/subscriptionTransactions?page=${page}`;
  return axios.get(url, configHeaders());
};

export const ChangePasswordApi = (params) => {
  let url = `${BASE_URL}/admin/changePassword`;
  return axios.post(url, params, configHeaders());
};
