import logo from "../assets/images/logo.png";
import user from "../assets/images/user.png";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import hamb from "../assets/images/menu.png";
import $ from "jquery";

function Header() {
  const navigate = useNavigate();
  const globalContext = useContext(GlobalContext);

  function handleClick() {
    sessionStorage.clear();
    globalContext.setLoggedIn(false);
    globalContext.setUserInfo({});
    navigate("/login");
  }

  function hideShowSidebar() {
    $(".admin-sidebar, .admin-logo-header, .admin-footer-side").toggle();
    $(".full-width-sidebar").toggleClass('active-new');
  }

  return (
    <header>
      <div className="header-main">
        <div className="top-bar">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-2">
                <div className="logo float-start admin-logo-header">
                  <img
                    src={logo}
                    alt="my-logo"
                    className="mt-2 zoom-in-zoom-out"
                  />
                </div>
              </div>

              <div className="col-10 full-width-sidebar">
                <img
                  src={hamb}
                  className="hamburger"
                  alt="sidebar"
                  onClick={hideShowSidebar}
                />
                <div className="header-right float-end">
                  <ul>
                    <li className="nav-item dropdown">
                      <img src={user} alt="user" />
                      <button
                        className="btn btn-link nav-link position-absolute  dropdown-toggle d-flex align-items-center"
                        id="bd-theme"
                        type="button"
                        aria-expanded="false"
                        data-bs-toggle="dropdown"
                        data-bs-display="static"
                      >
                        <svg className="bi  theme-icon-active">
                          <use href="#moon-stars-fill"></use>
                        </svg>
                      </button>
                      <ul
                        className="dropdown-menu dropdown-menu-end admin-drop"
                        aria-labelledby="bd-theme"
                      >
                        <li onClick={() => navigate("/changepassword")}><i class="fa fa-lock" aria-hidden="true"></i>Change Password</li>
                        <li>
                          <button
                            type="button"
                            className="dropdown-item d-flex align-items-center"
                            onClick={handleClick}
                            data-bs-theme-value="light"
                          >
                            <i className="fa fa-sign-out"></i>
                            Logout
                            <svg className="bi ms-auto d-none">
                              <use href="#check2"></use>
                            </svg>
                          </button>
                        </li>

                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
export default Header;
