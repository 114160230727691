import userpro from '../assets/images/userpro.png';
import subscription from '../assets/images/subscription.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useLocation } from 'react-router-dom';
import home from '../assets/images/home.png';
import transaction from '../assets/images/transaction.png';

const Navigation = () => {

  const location = useLocation();

  return (
    <>
      <nav>
        <ul>
          <li className={`${location.pathname === "/dashboard" ? "active" : ""}`}>
            <Link to="/dashboard"><img src={home} alt="home" />Dashboard</Link>
          </li>
          <li className={`${location.pathname === "/users" || location.pathname === "/userdetail" ? "active" : ""}`}>
            <Link to="/users?page=1&search=&type="><img src={userpro} alt="User" />User Management</Link>
          </li>
          <li className={`${location.pathname === "/subscriptions" || location.pathname === "/addSubscription" || location.pathname === "/editSubscription" || location.pathname === "/subscriptiondetail" ? "active" : ""}`}>
            <Link to="/subscriptions?page=1"><img src={subscription} alt="subscription" />Subscription Management</Link>
          </li>
          <li className={`${location.pathname === "/transactions" ? "active" : ""}`}>
            <Link to="/transactions?page=1&search=&start=&end="><img src={transaction} alt="home" />Transaction Management</Link>
          </li>
        </ul>
      </nav>

      <div className='mobile'>
        <Navbar bg="light" expand="lg">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/dashboard"><img src={home} alt="home" />Dashboard</Nav.Link>
              <Nav.Link href="/users?page=1&search=&type="><img src={userpro} alt="home" />User Management</Nav.Link>
              <Nav.Link href="/subscriptions?page=1"><img src={subscription} alt="home" />Subscription Management</Nav.Link>
              <Nav.Link href="/transactions?page=1&search=&start=&end="><img src={subscription} alt="home" />Transaction Management</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </>
  )
};

export default Navigation;

